import { css, html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';

import '@brightspace-ui-labs/autocomplete/autocomplete.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/inputs/input-fieldset.js';
import '@brightspace-ui/core/components/inputs/input-number.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/loading-spinner/loading-spinner.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import '@brightspace-ui/core/components/table/table-controls.js';
import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel.js';
import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel-summary-item.js';
import '@brightspace-ui/core/components/switch/switch.js';
import '@brightspace-ui/core/components/tag-list/tag-list.js';
import '@brightspace-ui/core/components/tag-list/tag-list-item.js';

import { bodySmallStyles, labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { radioStyles } from '@brightspace-ui/core/components/inputs/input-radio-styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { createModel } from '../../../../../../shared/models/models.js';
import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';

import '../../../../../shared/components/general/no-results/no-results.js';
import '../../../../../shared/components/general/nova-tooltip/nova-tooltip.js';
import { DEFAULT_CAREER_EXPLORER_DATA, EMPTY_LIGHTCAST_SUGGESTION } from '../../../../../../shared/models/schema/tenant/index.js';

import { NovaFormMixin } from '../../../../../shared/mixins/nova-form-mixin/nova-form-mixin.js';
import { NovaPermissionMixin } from '../../../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

const COMPANY_LIMIT = 5;
const COMPANY = 'company';
const INDUSTRY = 'industry';

export default class ManageCareerExplorer extends NovaPermissionMixin(NovaFormMixin(SkeletonMixin(LocalizeNova(RequesterMixin(nav(LitElement)))))) {

  static get properties() {
    return {
      tenant: { type: Object, reflect: false }, // required
      _careerExplorer: { type: Object, reflect: false },
      _filteredSkills: { type: Array, reflect: false },
      _jobsLoaded: { type: Boolean, reflect: false },
      _exportReady: { state: true },
      _isDownloading: { state: true },
      _suggestions: { type: Object, reflect: false },
      _suggestionsLoading: { type: Object, reflect: false },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      inputLabelStyles,
      radioStyles,
      selectStyles,
      tableStyles,
      labelStyles,
      css`
        .top-spacing {
          margin-top: 0.8rem;
        }

        .submit-button {
          align-items: center;
          display: flex;
        }

        .select-wrapper {
          margin-bottom: 1.5rem;
        }

        .input-wrapper {
          padding-bottom: 1.5rem;
        }

        .add-company-button {
          margin-top: 1.5rem;
        }

        .company-tag-list {
          margin-bottom: 1.5rem;
        }

        .d2l-input-select {
          display: flex;
        }

        .download-button-wrapper {
          position: relative;
        }

        .d2l-loading-spinner {
          align-items: center;
          bottom: 0;
          display: flex;
          left: 0;
          padding-left: 6px;
          position: absolute;
          right: 0;
          top: 0;
        }

        label {
          display: inline-block;
          margin-bottom: 7px;
          vertical-align: bottom;
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this._suggestions = { company: [], industry: [] };
    this._suggestionsLoading = { company: false, industry: false };
    this.skeleton = true;
    this._skills = [];
    this._filteredSkills = [];
    this._jobsLoaded = false;
    this._isDownloading = false;
    this._exportReady = false;
    this._careersLastUpdated = null;
    this._careerExplorer = { ...DEFAULT_CAREER_EXPLORER_DATA, ...this.tenant.careerExplorer };

    // replaces old company schema with new one if needed
    this._careerExplorer.companies = this.tenant.lightcastCompanies;
    this._careerExplorer.industries = this.tenant.lightcastIndustries;
  }

  firstUpdated() {
    this._filteredSkills = [...this._skills];
    this.shadowRoot.getElementById('companyName').value = this.tenant.name;

    if (!this.tenant.hasLightcastCompany) {
      this._updateSuggestions(COMPANY);
      this._openCaketray(COMPANY);
    }

    if (!this.tenant.hasLightcastIndustry) {
      if (this.tenant.hasLightcastCompany) {
        const companyId = this._careerExplorer.companies[0].id;
        this._getCompanyIndustry(companyId);
      }
      this._updateSuggestions(INDUSTRY);
      this._openCaketray(INDUSTRY);
    }
  }

  async _updateSuggestions(type) {
    this._suggestionsLoading = { ...this._suggestionsLoading, [type]: true };
    const searchValue = this.shadowRoot.getElementById(`${type}Name`).value;
    const results = searchValue ? await this.client.searchLightcast(searchValue, type) : [];
    this._suggestions = { ...this._suggestions, [type]: results };
    this._suggestionsLoading = { ...this._suggestionsLoading, [type]: false };
  }

  _getPanelDisplayString(type) {
    return this._careerExplorer[this._getPluralizedType(type)].map(item => item.name).join(', ');
  }

  _getPluralizedType(type) {
    const plurals = { company: 'companies', industry: 'industries' };
    return plurals[type];
  }

  _getSelectedCareerLocations(type) {
    return this._careerExplorer[this._getPluralizedType(type)];
  }

  _removeTagListItem({ currentTarget: tagListItem }) {
    const type = tagListItem.getAttribute('data-type');
    const itemId = tagListItem.getAttribute('data-list-id');
    this._careerExplorer[type] = this._careerExplorer[type].filter(({ id }) => id !== itemId);
    if (this._careerExplorer[type].length === 0) this._careerExplorer[type].push(EMPTY_LIGHTCAST_SUGGESTION);
    this.requestUpdate();
  }

  async _getCompanyIndustry(companyId) {
    const industrySearch = this.shadowRoot.getElementById('industryName');
    try {
      const { industryName } = await this.client.getIndustryFromCompanyID(companyId);
      industrySearch.value = industryName;
      await this._updateSuggestions(INDUSTRY);
    } catch (e) {
      // do nothing
    }
  }

  addSelectedItem(currentTarget, type) {
    const { id, selected } = currentTarget;
    const getSelected = () => {
      const items = this._getSelectedCareerLocations(type)
        .filter(item => item.id !== id && item.id !== EMPTY_LIGHTCAST_SUGGESTION.id);

      if (selected) {
        const selectedItem = this._suggestions[type].find(item => item.id === id);

        if (type !== COMPANY || items.length < COMPANY_LIMIT) {
          items.push(selectedItem);
          if (type === COMPANY && !this.tenant.hasLightcastIndustry) {
            this._getCompanyIndustry(id);
          }
        } else {
          // Wait 200ms then uncheck the selection
          setTimeout(() => { currentTarget.selected = false; }, 200);
          this.session.toast({ type: 'warning', message: `Couldn't add ${selectedItem.name}. There's a maximum of ${COMPANY_LIMIT} companies.` });
        }
      } else if (!items.length) {
        items.push(EMPTY_LIGHTCAST_SUGGESTION);
      }
      return items;
    };

    this._careerExplorer = { ...this._careerExplorer, [this._getPluralizedType(type)]: getSelected() };
  }

  getCaketray(id) {
    return this.shadowRoot.getElementById(`${id}-caketray`);
  }

  _openCaketray(id) {
    const ct = this.getCaketray(id);
    ct.expanded = true;
  }

  async _save() {
    try {
      delete this._careerExplorer.company;
      delete this._careerExplorer.industry;
      await this.client.updateCareerExplorerInfo(this.tenant.id, this._careerExplorer);
      this.session.toast({ type: 'default', message: 'Updating career explorer info' });
    } catch (e) {
      this.session.toast({ type: 'critical', message: 'Error updating career explorer info' });
    }
  }

  get _activeToggleTemplate() {
    return html`
      <d2l-switch
        id="active"
        @change="${this._toggleCareerExplorer}"
        ?on="${this.tenant.hasTag('careerExplorer')}"
        text="${this.localize('career-explorer.enable')}"
        text-position="end">
      </d2l-switch>
    `;
  }

  _tagListTemplate(type) {
    return this._careerExplorer[this._getPluralizedType(type)].map(({ id, name: itemName }) => html`
      <d2l-tag-list-item
        @d2l-tag-list-item-clear="${this._removeTagListItem}"
        ?clearable=${id !== EMPTY_LIGHTCAST_SUGGESTION.id && this.canUpdate}
        data-list-id="${id}"
        data-testid="career-filter-tag"
        data-list-name="${itemName}"
        data-type="${this._getPluralizedType(type)}"
        key="${id}"
        text="${itemName}">
      </d2l-tag-list-item>
    `);
  }

  _suggestionsListTemplate(type) {
    const isLoading = this.skeleton && this._suggestionsLoading[type];
    const handleSelect = ({ currentTarget }) => this.addSelectedItem(currentTarget, type);

    if (!isLoading && !this._suggestions[type]?.length) {
      return html`<d2l-list-item label="No results">No results found</d2l-list-item>`;
    }

    return this._suggestions[type]?.length > 0 ? this._suggestions[type].map(s => html`
      <d2l-list-item
        @d2l-list-item-selected=${handleSelect}
        selectable
        ?selected=${this._getSelectedCareerLocations(type).find(({ id }) => id === s.id)}
        label="${s.name}"
        id="${s.id}"
        key="${s.id}">
        ${this.getDisplayValue(s)}
      </d2l-list-item>
    `) : html`<no-results .skeleton="${isLoading}"></no-results>`;
  }

  _panelTemplate(type, title) {
    const searchValue = this.shadowRoot.getElementById(`${type}Name`)?.value;
    const handleUpdate = () => this._updateSuggestions(type);

    return html`
      <d2l-collapsible-panel @d2l-collapsible-panel-expand="${handleUpdate}" id="${type}-caketray" panel-title=${title}>
        <d2l-collapsible-panel-summary-item slot="summary" text=${this._getPanelDisplayString(type)}>
        </d2l-collapsible-panel-summary-item>

        <d2l-tag-list class="company-tag-list" description="${this._getPluralizedType(type)} associated with this tenant">
          ${this._tagListTemplate(type)}
        </d2l-tag-list>

        <div class="input-wrapper">
          <d2l-input-text
            @keyup=${handleUpdate}
            class="d2l-skeletize"
            id="${type}Name"
            label="${type} name"
            label-hidden
            name="${type}Name"></d2l-input-text>
        </div>

        <d2l-list class="d2l-skeletize">
          ${searchValue ? this._suggestionsListTemplate(type) : nothing}
        </d2l-list>
      </d2l-collapsible-panel>
    `;
  }

  get _otherSettingsTemplate() {
    const settingUpdated = e => {
      const { id, checked } = e.target;
      let { value } = e.target;
      if (checked !== undefined) value = checked;
      this._careerExplorer = { ...this._careerExplorer, settings: { ...this._careerExplorer.settings, [id]: value } };
    };
    return html`
      <d2l-collapsible-panel
        id="other-settings-caketray"
        @change="${settingUpdated}"
        panel-title="Other Settings">
        <d2l-collapsible-panel-summary-item
          slot="summary"
          text="Settings that have an impact on the career filter experience">
        </d2l-collapsible-panel-summary-item>
          <div class="input-wrapper">
            <label class="d2l-label-text">Career filter selection mode</label>
            <p class="d2l-body-small">Note: the "multi" option is experimental and may result in unexpected behaviour.</p>
            <select id="careerFilterSelectionMode" .value="${this._careerExplorer.settings?.careerFilterSelectionMode}" class="d2l-input-select top-spacing">
              <option value="single">Single</option>
              <option value="multi">Multi</option>
            </select>
          </div>

          <div class="input-wrapper">
            <label class="d2l-label-text">Skills aggregation strategy</label>
            <p class="d2l-body-small">Note: This only affects the experimental "multi" career filter selection mode.</p>
            <select id="skillsAggregationStrategy"
              .value="${this._careerExplorer.settings.skillsAggregationStrategy}"
              class="d2l-input-select top-spacing">
                <option value="intersect">Intersect skills</option>
                <option value="additiveUnion">Additive union of skills</option>
            </select>
            <div class="top-spacing">
              <p class="d2l-body-small"><strong>Intersect skills:</strong> Before the activity query, skills are filtered down to  those that are common between all selected careers in the career filter. Activities which possess at least one of those intersecting skills will be included in the query result. Skills that do not appear in ALL other selected careers are not included in the query and don't affect the result.</p>
              <p class="d2l-body-small"><strong>Additive union of skills:</strong> Before the activity query, skill significances are summed up across their occurrences in each selected career. For example if two careers have the 'C#' skill with significance of '10', then the overall weight of 'C#' in the query will become '20'. Activities which possess at least one of ANY of the selected careers' skills will be included in the query result.</p>
            </div>
          </div>

          <div class="input-wrapper">
            <label class="d2l-label-text">Skill types included during filtering</label>
            <select id="skillsIncluded" .value="${this._careerExplorer.settings.skillsIncluded}" class="d2l-input-select">
              <option value="both">All</option>
              <option value="common">Common</option>
              <option value="specialized">Specialized</option>
            </select>
          </div>

          <div class="input-wrapper">
            <d2l-input-number
              id="skillsSignificanceThreshold"
              label="Skills significance threshold"
              .value="${this._careerExplorer.settings.skillsSignificanceThreshold}">
            </d2l-input-number>
          </div>

          <div class="input-wrapper">
            <d2l-input-checkbox
              id="showLots"
              ?checked=${this._careerExplorer.settings.showLots}>
              Show LOT occupations instead of job titles in the Career filter
            </d2l-input-checkbox>
          </div>
      </d2l-collapsible-panel>`;
  }

  getDisplayValue(suggestion) {
    if (!suggestion) return '';
    return suggestion.id === '-1' ? suggestion.name : `${suggestion.name} (${suggestion.id})`;
  }

  _dispatchUpdateTenant() {
    this.dispatchEvent(new CustomEvent('update-tenant', { detail: { tenant: this.tenant } }));
  }

  async _toggleCareerExplorer() {
    const isActive = this.tenant.hasTag('careerExplorer');
    const toast = { message: this.localize('career-explorer.toggleSuccess'), type: 'default' };
    this.tenant.tags.setTag('careerExplorer', !isActive);

    try {
      this.tenant = createModel(await this.client.updateGeneral(this.tenant));
      this._dispatchUpdateTenant();
    } catch (e) {
      const activeSwitch = this.shadowRoot.getElementById('active');
      activeSwitch.on = isActive;
      this.tenant.tags.setTag('careerExplorer', isActive);
      toast.message = await e.text();
      toast.type = 'critical';
    }

    this.session.toast(toast);
  }

  render() {
    return html`
      <d2l-form>
        ${this._activeToggleTemplate}
        <p>${this._panelTemplate(COMPANY, 'Lightcast company')}</p>
        <p>${this._panelTemplate(INDUSTRY, 'NAICS industry')}</p>
        <p>${this._otherSettingsTemplate}</p>
        <div id="submit-general" class="submit-button">
          <d2l-button @click=${this._save} primary>Save</d2l-button>
        </div>
      </d2l-form>

      <p>${this._jobsTableTemplate}</p>
    `;
  }

  _skillFilterChange(e) {
    const filterValue = e.target.value.toLowerCase();
    this._filteredSkills = this._skills.filter(s => {
      const hasValue = attribute => attribute?.toLowerCase().includes(filterValue);
      return hasValue(s.skillId) || hasValue(s.skillName) || hasValue(s.type) || hasValue(s.jobId) || hasValue(s.jobName) || hasValue(s.lotId) || hasValue(s.lotName);
    });
  }

  async _loadJobs() {
    if (this._jobsLoaded) return;
    const jobs = (await this.client.getJobsList(this.tenant.id)).jobTitles || [];
    if (jobs.length === 0) return;

    this._careersLastUpdated = jobs[jobs.length - 1].lastUpdated;
    this._skills = [];
    for (const job of jobs) {
      const { jobId, lotId, jobName, lotName } = job;
      for (const [skillId, { significance, name: skillName }] of Object.entries(job.skills.specialized)) {
        this._skills.push({ jobId, jobName, lotId, lotName, skillId, skillName, significance, type: 'specialized' });
      }
      for (const [skillId, { significance, name: skillName }] of Object.entries(job.skills.common)) {
        this._skills.push({ jobId, jobName, lotId, lotName, skillId, skillName, significance, type: 'common' });
      }
    }
    this._skills.sort((a, b) => {
      if (a.jobName < b.jobName) return -1;
      if (a.jobName > b.jobName) return 1;
      if (a.significance > b.significance) return -1;
      if (a.significance < b.significance) return 1;
      return 0;
    });
    this._filteredSkills = [...this._skills];
    this._jobsLoaded = true;
  }

  async _setExportReadyStatus() {
    const exportStatus = await this._checkExportStatus();
    this._exportReady = this._careersLastUpdated <= exportStatus.lastModified;
  }

  async _downloadJobsExport() {
    this._isDownloading = true;
    try {
      await this._setExportReadyStatus();
      if (this._exportReady) {
        await this.client.createJobsExport(this.tenant.id);
        this.session.toast({ type: 'default', message: 'Download complete.' });
      } else {
        this.session.toast({ type: 'warning', message: 'New career data is still being processed. Try again in a bit.' });
      }
      await this.client.createJobsExport(this.tenant.id);
      this.session.toast({ type: 'default', message: 'Download complete.' });
    } catch (e) {
      this.session.toast({ type: 'critical', message: 'Error generating CSV report of job data.' });
    } finally {
      this._isDownloading = false;
    }
  }

  async _checkExportStatus() {
    return await this.client.checkJobsExportStatus(this.tenant.id);
  }

  get _downloadExportDisabled() {
    return this._isDownloading || !this._jobsLoaded;
  }

  get _jobsTableTemplate() {
    const reloadJobs = async() => {
      this._jobsLoaded = false;
      this._exportReady = false;
      await this._loadJobs();
    };
    return html`
      <d2l-collapsible-panel
        id="jobs-caketray"
        @d2l-collapsible-panel-expand="${this._loadJobs}"
        panel-title="${this.tenant.name} Jobs">
        <d2l-collapsible-panel-summary-item
          slot="summary"
          text="A summary of all the jobs and their corresponding skills for ${this.tenant.name}">
        </d2l-collapsible-panel-summary-item>
          ${!this._jobsLoaded ? html`<div><d2l-loading-spinner></d2l-loading-spinner></div>` : html`
            <div class="input-wrapper">
              <d2l-input-text id="skillFilter"
                              label="Skill filter"
                              @keyup=${this._skillFilterChange}
                              name="skillFilterChange"></d2l-input-text>
            </div>

          <d2l-table-wrapper sticky-headers>
            <d2l-table-controls slot="controls">
              <d2l-button-icon icon="tier1:refresh" text="Refresh" @click="${reloadJobs}"></d2l-button-icon>
              <div class="download-button-wrapper">
                <d2l-button-subtle
                  ?disabled=${this._downloadExportDisabled}
                  icon="tier1:download"
                  text="Download CSV"
                  @click="${this._downloadJobsExport}"
                >
                </d2l-button-subtle>
                ${this._isDownloading ? html`
                  <div class="d2l-loading-spinner">
                    <d2l-loading-spinner size="30"></d2l-loading-spinner>
                  </div>` : nothing}
              </div>
            </d2l-table-controls>
            <table class="d2l-table">
              <thead>
              <tr>
                <th>Job Name (ID)</th>
                <th>LOT Name (ID)</th>
                <th>Skill Name (ID)</th>
                <th>Significance</th>
                <th>Skill Type</th>
              </tr>
              </thead>
              <tbody>
              ${this._filteredSkills.map(job => html`
            <tr>
              <td>${job.jobName}<br>(${job.jobId})</td>
              <td>${job.lotName}<br>(${job.lotId})</td>
              <td>${job.skillName}<br>(${job.skillId})</td>
              <td>${job.significance}</td>
              <td><d2l-status-indicator state="${job.type === 'specialized' ? 'alert' : 'success'}" text="${job.type}" bold></d2l-status-indicator></td>
            </tr>
          `)}
              </tbody>
            </table>
          </d2l-table-wrapper>
          `}
      </d2l-collapsible-panel>
    `;
  }
}

window.customElements.define('manage-career-explorer', ManageCareerExplorer);
