import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/tabs/tabs.js';
import '@brightspace-ui/core/components/tabs/tab-panel.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import '@brightspace-ui-labs/accordion/accordion.js';
import '@brightspace-ui-labs/accordion/accordion-collapse.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/button/button.js';

import './features-grid/features-grid.js';
import '../../../shared/components/general/app-link/app-link.js';
import '../../../shared/components/activities/activity-table/activity-table.js';
import '../../../shared/components/general/nova-side-panel/nova-side-panel.js';
import '../../../shared/components/general/nova-side-panel-item/nova-side-panel-item.js';
import '../../../admin/components/nova-tenant-list/nova-tenant-list.js';

import { css, html, LitElement } from 'lit';
import { heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';

/**
 * The view for showing a list of tenants
 */
export default class ViewAdmin extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      _tenants: { type: Array, attribute: false },
      _admins: { type: Array },
      _providers: { type: Array },
      _employers: { type: Array },
      _selectedType: { type: String, attribute: false },
      _isSkillsLoading: { type: Boolean, attribute: false },
      _filteredSkills: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return [
      selectStyles,
      inputLabelStyles,
      heading1Styles,
      heading2Styles,
      heading3Styles,
      tableStyles,
      css`
        :host {
          display: block;
        }

        d2l-tabs {
          max-width: 1170px;
          width: 90vw;
        }

        .add-button, .add-tenant {
          display: block;
          margin-bottom: 25px;
        }

        .d2l-heading-1 {
          margin-top: 0;
        }

        .create-tenant {
          align-items: center;
          column-gap: 10px;
          display: grid;
          grid-template-columns: auto 1fr;
        }
        .select-wrapper {
          display: flex;
          flex-wrap: wrap;
          width: min-content;
        }

        .select-wrapper > label {
          width: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();
    this._tenants = [];
    this._providers = [];
    this._employers = [];
    this._admins = [];
    this._isSkillsLoading = false;
    this.skeleton = true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    this._tenants = await this.client.listTenants();
    this.skeleton = false;
  }

  updated(changedProperties) {
    if (changedProperties.has('_tenants')) {
      this._refreshTenantsList();
    }
    super.updated(changedProperties);
  }

  _refreshTenantsList() {
    this._admins = this._tenants?.filter(tenant => tenant.type === 'admin');
    this._providers = this._tenants?.filter(tenant => tenant.type === 'provider');
    this._employers = this._tenants?.filter(tenant => tenant.type === 'employer');
    this.requestUpdate();
  }

  render() {
    if (this.session?.user?.roles?.length === 0 && this.session?.tenant?.hasTag('adminRoles')) this.navigateWithoutHistory('/403');
    if (this.session.loggedIn && this.session.tenant.type !== 'admin') {
      this.navigate('/');
    }

    return html`
      <nova-side-panel .skeleton="${this.skeleton}">
        <nova-side-panel-item panel="activities" label=${this.localize('view-admin.activities')} icon="tier1:assignments">
          <h1 class="d2l-heading-1">${this.localize('view-admin.activities')}</h1>
          <app-link class="add-button" href="activities/add"><d2l-button>${this.localize('general.addActivity')}</d2l-button></app-link>
          <activity-table .providers=${this._providers}></activity-table>
        </nova-side-panel-item>

        <nova-side-panel-item-group
            expanded
            label="${this.localize('view-admin.tenants')}"
            icon="tier1:group">
            <nova-side-panel-item panel="provider" label=${this.localize('view-admin.provider.heading1')}>
                <nova-tenant-list
                  .updatePermissions="${'provider:create'}"
                  .tenants="${this._providers}"
                  type="provider"></nova-tenant-list>
            </nova-side-panel-item>
            <nova-side-panel-item panel="employer" label=${this.localize('view-admin.employer.heading1')}>
              <nova-tenant-list
                .updatePermissions="${'employer:create'}"
                .tenants="${this._employers}"
                type="employer"></nova-tenant-list>
              </app-link>
            </nova-side-panel-item>
            <nova-side-panel-item panel="admin" label=${this.localize('general.admin')}>
              <nova-tenant-list
                disable-create
                .tenants="${this._admins}"
                type="admin"></nova-tenant-list>
              </app-link>
            </nova-side-panel-item>
          </nova-side-panel-item-group>

          <nova-side-panel-item .viewPermissions=${['features:view']} panel="features" icon="tier1:tools" label=${this.localize('view-admin.features')}>
            <features-grid  .updatePermissions=${['features:update']} .tenants=${this._tenants}></features-grid>
          </nova-side-panel-item>
      </nova-side-panel>
    `;
  }

}

window.customElements.define('view-admin', ViewAdmin);
